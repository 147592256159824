/** @format */

import logo from "./logo.svg";
import "./App.css";
import MainComponent from "./MainComponent";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Blogs from "./Components/Blogs";

function App() {
	return (
		<div className="App">
			<Router>
				<Routes>
					<Route path="/" element={<MainComponent />} exact />
					<Route path="/blogs" element={<Blogs />} exact />
				</Routes>
			</Router>
		</div>
	);
}

export default App;
