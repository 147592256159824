/** @format */
import React, { useState } from "react";
import {
	MDBContainer,
	MDBNavbar,
	MDBNavbarBrand,
	MDBNavbarToggler,
	MDBIcon,
	MDBNavbarNav,
	MDBNavbarItem,
	MDBNavbarLink,
	MDBBtn,
	MDBCollapse,
} from "mdb-react-ui-kit";

export default function App() {
	const [openNavRight, setOpenNavRight] = useState(false);

	return (
		<header>
			<MDBNavbar expand="lg" light bgColor="white" className="custom-navbar">
				<MDBContainer fluid>
					<MDBNavbarBrand href="#">
						<img
							src="/hextGen_logo.png"
							height="60"
							alt="HextGen Logo"
							loading="lazy"
						/>
					</MDBNavbarBrand>
					<MDBNavbarToggler
						type="button"
						aria-controls="navbarRightAlignExample"
						aria-expanded="false"
						aria-label="Toggle navigation"
						onClick={() => setOpenNavRight(!openNavRight)}>
						<MDBIcon icon="bars" fas />
					</MDBNavbarToggler>

					<MDBCollapse navbar open={openNavRight}>
						<MDBNavbarNav right fullWidth={false} className="mb-2 mb-lg-0">
							<MDBNavbarItem>
								<MDBNavbarLink active className="custom-link" href="#aboutUs">
									ABOUT
								</MDBNavbarLink>
							</MDBNavbarItem>
							<MDBNavbarItem>
								<MDBNavbarLink
									active
									className="custom-link"
									href="#ourServices">
									OUR SERVICES
								</MDBNavbarLink>
							</MDBNavbarItem>
							<MDBNavbarItem>
								<MDBNavbarLink active className="custom-link" href="#products">
									PRODUCTS
								</MDBNavbarLink>
							</MDBNavbarItem>
							<MDBNavbarItem>
								<MDBNavbarLink active className="custom-link" href="/blogs">
									BLOGS
								</MDBNavbarLink>
							</MDBNavbarItem>
							<MDBNavbarItem>
								<MDBNavbarLink active className="custom-link" href="#contactUs">
									CONTACT US
								</MDBNavbarLink>
							</MDBNavbarItem>
						</MDBNavbarNav>
					</MDBCollapse>
				</MDBContainer>
			</MDBNavbar>

			<div className="bg-color" id="aboutUs">
				<MDBContainer>
					<div className="row about-section">
						<img
							alt="Doctor"
							className="col-12 col-md-6 mt-5 mb-5"
							src="/docimage.jpg"
						/>
						<div className="col-md-6 mt-5">
							<h1 className="section-title mb-3">
								Revolutionize Your Hospital with{" "}
								<span style={{ color: "#4283AD" }}>HextGen</span>
							</h1>
							<p className="section-description mb-3">
								Empowering clinics and hospitals to transform operations and
								stay seamlessly connected with patients through our innovative
								software.
								<br /> HextGen offers innovative software solutions for clinics
								and hospitals, transforming their daily operations across
								multiple fronts including Out-Patient and In-Patient management,
								Laboratory, Pharmacy, Digitalized Case Taking, and Revenue
								Analytics.
							</p>
							<div className="text-center">
								<MDBBtn
									tag="a"
									size="lg"
									href="#contactUs"
									className="custom-btn">
									Register
								</MDBBtn>
							</div>
						</div>
					</div>
				</MDBContainer>
			</div>
		</header>
	);
}
