/** @format */

import React, { useState } from "react";
import {
	MDBCard,
	MDBCardBody,
	MDBCardTitle,
	MDBCardText,
	MDBCardHeader,
	MDBCardFooter,
	MDBBtn,
	MDBContainer,
} from "mdb-react-ui-kit";

export default function App() {
	return (
		<MDBContainer className="mb-5 mt-5" id="products">
			<h2 className="pt-5">PRODUCTS</h2>
			<div className="row mt-3">
				<div className="col-md-6">
					<MDBCard alignment="left">
						<MDBCardBody>
							<MDBCardTitle className="header-center mb-3">
								Clinic Management Software
							</MDBCardTitle>
							<MDBCardText>
								Elevate your clinic's efficiency with our Clinic Management
								Software. You'll gain access to comprehensive appointment
								scheduling, patient management, and reporting insights.
							</MDBCardText>
							<MDBCardText>
								Have questions or need a tailored solution?{" "}
								<a href="#contactUs" style={{ fontWeight: "bold" }}>
									Contact Us
								</a>{" "}
								and let's discuss how we can meet the unique needs of your
								clinic.
							</MDBCardText>
						</MDBCardBody>
						<MDBCardFooter className="text-muted header-center fw-bold">
							Special Launch Offer{" "}
							<div className="text-danger">
								RS<del> 2400</del> <ins> 2000/month</ins>
							</div>
						</MDBCardFooter>
					</MDBCard>
				</div>
				<div className="col-md-6">
					<MDBCard alignment="left">
						<MDBCardBody>
							<MDBCardTitle className="header-center mb-3">
								Multi-Speciality Management Software
							</MDBCardTitle>
							<MDBCardText>
								Transform your multi-speciality practice with our robust
								software solution. This plan offers advanced features designed
								for complex healthcare environments, including multi-department
								scheduling and analytics.
							</MDBCardText>
							<MDBCardText>
								For custom pricing or to explore more features, please{" "}
								<a href="#contactUs" style={{ fontWeight: "bold" }}>
									Contact Us.
								</a>{" "}
								Our team is ready to assist you with personalized options that
								fit your practice.
							</MDBCardText>
						</MDBCardBody>
						<MDBCardFooter className="text-muted header-center fw-bold">
							To Know More
							<div className="text-danger">
								<a href="#contactUs" style={{ fontWeight: "bold" }}>
									Contact Us.
								</a>{" "}
							</div>
						</MDBCardFooter>
					</MDBCard>
				</div>
			</div>
		</MDBContainer>
	);
}
