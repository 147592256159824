/** @format */

import React, { useEffect, useState } from "react";
import Header from "./Header";
import {
	MDBContainer,
	MDBNavbar,
	MDBNavbarBrand,
	MDBNavbarToggler,
	MDBIcon,
	MDBNavbarNav,
	MDBNavbarItem,
	MDBNavbarLink,
	MDBBtn,
	MDBCollapse,
} from "mdb-react-ui-kit";

export default function Blogs() {
	const [openNavRight, setOpenNavRight] = useState(false);
	return (
		<>
			<header>
				<MDBNavbar expand="lg" light bgColor="white" className="custom-navbar">
					<MDBContainer fluid>
						<MDBNavbarBrand href="#">
							<img
								src="/hextGen_logo.png"
								height="60"
								alt="HextGen Logo"
								loading="lazy"
							/>
						</MDBNavbarBrand>
						<MDBNavbarToggler
							type="button"
							aria-controls="navbarRightAlignExample"
							aria-expanded="false"
							aria-label="Toggle navigation"
							onClick={() => setOpenNavRight(!openNavRight)}>
							<MDBIcon icon="bars" fas />
						</MDBNavbarToggler>

						<MDBCollapse navbar open={openNavRight}>
							<MDBNavbarNav right fullWidth={false} className="mb-2 mb-lg-0">
								<MDBNavbarItem>
									<MDBNavbarLink
										active
										className="custom-link"
										href="/#aboutUs">
										ABOUT
									</MDBNavbarLink>
								</MDBNavbarItem>
								<MDBNavbarItem>
									<MDBNavbarLink
										active
										className="custom-link"
										href="/#ourServices">
										OUR SERVICES
									</MDBNavbarLink>
								</MDBNavbarItem>
								<MDBNavbarItem>
									<MDBNavbarLink
										active
										className="custom-link"
										href="/#products">
										PRODUCTS
									</MDBNavbarLink>
								</MDBNavbarItem>
								<MDBNavbarItem>
									<MDBNavbarLink active className="custom-link" href="/blogs">
										BLOGS
									</MDBNavbarLink>
								</MDBNavbarItem>
								<MDBNavbarItem>
									<MDBNavbarLink
										active
										className="custom-link"
										href="/#contactUs">
										CONTACT US
									</MDBNavbarLink>
								</MDBNavbarItem>
							</MDBNavbarNav>
						</MDBCollapse>
					</MDBContainer>
				</MDBNavbar>
			</header>
			<h1 className="mt-5 text-center">Landing Soon...</h1>
		</>
	);
}
