/** @format */

import React from "react";
import {
	MDBFooter,
	MDBContainer,
	MDBCol,
	MDBRow,
	MDBIcon,
	MDBBtn,
} from "mdb-react-ui-kit";

export default function App() {
	return (
		<MDBFooter className="bg-light text-center text-white">
			<MDBContainer className="p-4 pb-0">
				<section className="mb-4">
					<MDBBtn
						floating
						className="m-1"
						style={{ backgroundColor: "#3b5998" }}
						href="#!"
						role="button">
						<MDBIcon fab icon="facebook-f" />
					</MDBBtn>

					<MDBBtn
						floating
						className="m-1"
						style={{ backgroundColor: "#55acee" }}
						href="#!"
						role="button">
						<MDBIcon fab icon="twitter" />
					</MDBBtn>

					<MDBBtn
						floating
						className="m-1"
						style={{ backgroundColor: "#dd4b39" }}
						href="#!"
						role="button">
						<MDBIcon fab icon="google" />
					</MDBBtn>
					<MDBBtn
						floating
						className="m-1"
						style={{ backgroundColor: "#ac2bac" }}
						href="#!"
						role="button">
						<MDBIcon fab icon="instagram" />
					</MDBBtn>

					<MDBBtn
						floating
						className="m-1"
						style={{ backgroundColor: "#0082ca" }}
						href="#!"
						role="button">
						<MDBIcon fab icon="linkedin-in" />
					</MDBBtn>
				</section>
			</MDBContainer>

			<hr className="my-4 text-dark" />
			<MDBRow>
				<MDBCol className="text-center text-dark">
					<p className="mb-0">
						&copy; {new Date().getFullYear()}{" "}
						<a href="https://hextgen.com/">HextGen</a>. All rights reserved.
					</p>
				</MDBCol>
			</MDBRow>
		</MDBFooter>
	);
}
